<template>
  <div class="">
    <div class="all">
      <div class="header">
        <div class="left" @click="goBack">
          <el-icon><ArrowLeftBold /></el-icon>
        </div>

        <div class="title">
          <div>
            {{ User.userName }}
          </div>

          <div v-if="User.status == '0'" class="statu"></div>
          <div v-else class="statu1"></div>
        </div>

        <div style="width: 40px; height: 40px"></div>
      </div>

      <div class="main" ref="msglist">
        <div v-for="(item, index) in messageList" :key="index">
          <div
            class="left"
            v-if="
              item.userType == '2' && !isPosition
                ? item.userType == '2' && !isPosition
                : item.userType == '1' && isPosition
            "
          >
            <div class="avatar">
              <el-avatar
                shape="square"
                :size="34"
                fit="cover"
                :src="
                  ImageUrl + item.avatar
                    ? ImageUrl + item.avatar
                    : require('@/assets/default-user.png')
                "
              ></el-avatar>
            </div>
            <div class="text">{{ item.message }}</div>
          </div>

          <div
            class="right"
            v-if="
              item.userType == '1' && !isPosition
                ? item.userType == '1' && !isPosition
                : item.userType == '2' && isPosition
            "
          >
            <div class="text-box">
              <div class="text">{{ item.message }}</div>
            </div>
            <div class="avatar">
              <el-avatar
                shape="square"
                :size="34"
                fit="cover"
                :src="
                  ImageUrl + item.avatar
                    ? ImageUrl + item.avatar
                    : require('@/assets/default-user.png')
                "
              ></el-avatar>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <el-input class="input" v-model="messageImgVal" placeholder="Please input" />

        <div class="right">
          <el-button class="btn" @click="postfaChatsend()" type="primary">Send</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  Search,
  Clock,
  ArrowRightBold,
  ArrowLeftBold,
  Edit,
} from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import {
  ref,
  defineProps,
  watch,
  inject,
  onMounted,
  nextTick,
  onUnmounted,
  watchEffect,
  reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import request from "@/utils/request.js";
import { getToken } from "@/utils/auth";
import {} from "vue";
const router = useRouter();
const route = useRoute();
const messageImgVal = ref("");
const msglist = ref(null);
const isPosition = ref(false);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;

const User = reactive({
  userName: "",
  status: "",
  id: null,
  avatarImage: "",
});

const socket = inject("socket");

const messageList = ref("");

onMounted(() => {
  console.log(route.query.type, "route.query.type");
  if (route.query.type == "1") {
    isPosition.value = true;
  }
  let token = getToken();
  const ws = socket("1", token);

  let obj = route.query;
  ws.onmessage = ({ data }) => {
    if (data[0] == "{") {
      let dataParse = JSON.parse(data);

      if (dataParse.type == "mailbox") {
        getChatInfo(obj.id);
      }
    }
  };

  console.log(ws, "wssss");
  console.log(route.query, "route.quert");

  getUserInfo(obj);
  getChatInfo(obj.id);
});

const getUserInfo = (obj) => {
  User.userName = obj.nickname;
  User.status = obj.status;
  User.id = obj.id;
  User.avatarImage = obj.avatarImage;
};

async function getChatInfo(targetId) {
  console.log(targetId, "用户-----idididi");

  const { code, data } = await request.get(
    "/system/faChat/info?pageSize=10000000&targetId=" + targetId
  );
  if (code == 200) {
    if (data) {
      // data.infos.forEach(item => {
      //     item.userType = '2'
      // })
      messageList.value = data.infos;

      console.log(messageList.value, "messageList.value");
    } else {
      messageList.value = [];
    }

    nextTick(() => {
      msgScroll();
    });
  } else {
    messageList.value = [];
    ElMessage("Server error.");
  }
}

async function postfaChatsend(type, e) {
  if (messageImgVal.value == "") {
    return;
  }
  let data = {
    targetId: User.id,
    message: messageImgVal.value,
  };

  const { code } = await request.post("/system/faChat/send", data);
  if (code == 200) {
    messageImgVal.value = "";
    getChatInfo(User.id);
  } else {
    ElMessage("Server error.");
  }
}

function msgScroll() {
  if (msglist.value && msglist.value.scrollHeight) {
    msglist.value.scrollTop = msglist.value.scrollHeight;
  }
}

//返回上页
const goBack = () => {
  console.log("11");
  router.back();
};
</script>

<style lang="scss" scoped>
:deep(.el-button) {
  width: 100%;
  height: 100%;
}
.all {
  display: flex;
  flex-direction: column;
  height: 95vh;

  .header {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background-color: skyblue;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    position: fixed;
    z-index: 999;

    .title {
      display: flex;
      color: #000000;
      font-weight: 600;

      .statu {
        margin-top: 14px;
        margin-left: 5px;
        border-radius: 100%;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #eee;
        width: 12px;
        height: 12px;
      }

      .statu1 {
        margin-top: 14px;
        margin-left: 5px;
        border-radius: 100%;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: green;
        width: 12px;
        height: 12px;
      }
    }

    .left {
      margin-left: 10px;
      width: 30px;
    }
  }

  .main {
    // flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 2;
    margin-top: 45px;
    margin-bottom: 45px;
    // margin:45px 0;
    // height:600px;
    .left {
      // display: flex;
      position: relative;
      margin: 10px 0px;

      &::after {
        position: absolute;
        content: "";
        top: 8px;
        box-sizing: border-box;
        left: 41px;
        height: 20px;
        width: 30px;
        border: 10px solid;
        border-color: transparent #eee transparent transparent;
      }

      .avatar {
        width: 40px;
        margin-right: 10px;
        position: absolute;
        left: 15px;
        top: 0px;
      }

      .text {
        background-color: #eee;
        padding: 8px 15px;
        white-space: pre-line;
        word-break: break-word;
        border-radius: 6px;
        width: 200px;
        margin-left: 70px;
      }
    }

    .right {
      display: flex;
      position: relative;
      margin: 10px 0px;

      &::after {
        position: absolute;
        content: "";
        top: 8px;
        box-sizing: border-box;
        right: 41px;
        height: 20px;
        width: 30px;
        border: 10px solid;
        border-color: transparent transparent transparent #eee;
      }

      .text-box {
        width: 100%;

        .text {
          width: 200px;
          background-color: #eee;
          padding: 8px 15px;
          white-space: pre-line;
          word-break: break-word;
          border-radius: 6px;
          // margin: 10px 0;
          // width:200px;
          // flex: 1;
          margin-right: 70px;
          float: right;
        }
      }

      .avatar {
        width: 40px;
        // margin-right: 10px;
        position: absolute;
        right: 8px;
        // bottom:300px;
        top: 0px;
      }
    }
  }

  .footer {
    z-index: 999;
    height: 40px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    position: fixed;
    bottom: 0px;

    .input {
      height: 100%;
      width: 80%;
    }

    .right {
      width: 20%;
    }
  }
}
</style>
